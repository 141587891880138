// External Dependencies
import React from 'react';
import { Link } from 'gatsby';

// Internal Dependencies
import HeaderGeneric from '../components/HeaderGeneric';
import Layout from '../components/layout';
import Meta from '../components/Meta';

// Component Definition
const TMEA2021RaffleEntry = () => (
  <Layout hideFooter>
    <Meta title="Raffle Enter Success" />
    <HeaderGeneric
      subtitle="Winners will be announced via email and social media at the conclusion of the convention."
      title={`Thank you for entering the ${process.env.GATSBY_APP_CONVENTION_NAME} raffle!`}
    />
    <div className="center">
      <Link
        className="button special-2"
        to="/"
      >
        Home
      </Link>
    </div>
  </Layout>
);

export default TMEA2021RaffleEntry;
